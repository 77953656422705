@import "./fonts";
@import "~bootstrap/scss/_functions.scss";
@import "./bootstrap_customizations";
@import "~bootstrap/scss/bootstrap";
@import "./loader";

html,
body {
  min-height: 100vh;
  width: 100%;
}

.btn:not(.btn-link) {
  color: $mm-light-stone;
}
