@import "../../styles/colors";

.page-footer {
  text-align: center;
  padding-top: 2rem;

  .ce-logo {
    height: 80px;
    width: auto;
    margin: 1em 0 0.75em;
  }

  .copyright-text {
    color: $mm-grey-text;
    font-size: 0.8em;
  }
}

@media all and (max-width: 767px) {
  .page-footer {
    padding-bottom: 3.5rem;
  }
}
