@import "../../styles/colors";

.loading-box {
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    padding: 0.5rem 0;
    color: $mm-grey-text;
  }
}
