.back-to-top-button {
  position: fixed;
  left: 1rem;
  bottom: 1rem;
  z-index: 9;
}

@media all and (min-width: 769px) {
  .back-to-top-button {
    left: 2rem;
    bottom: 2rem;
  }
}
