.app-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 100vh;

  > main {
    flex: 1 1 auto;
  }
}
