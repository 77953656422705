@font-face {
  font-family: "ProximaNova";
  src: url(../fonts/ProximaNova-Regular.woff2) format("woff2"),
    url(../fonts/ProximaNova-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "ProximaNova";
  src: url(../fonts/ProximaNova-Bold.woff2) format("woff2"),
    url(../fonts/ProximaNova-Bold.woff) format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
