@import "../../styles/colors";

.page-header {
  .color-stripe {
    width: 100%;
    height: 0.5rem;
    display: flex;
    flex-wrap: nowrap;

    > div {
      min-width: 25%;
      flex: 0 0 25%;
      height: 100%;

      &.stripe-1 {
        background-color: $mm-green;
      }
      &.stripe-2 {
        background-color: $mm-light-blue;
      }
      &.stripe-3 {
        background-color: $mm-light-red;
      }
      &.stripe-4 {
        background-color: $mm-light-purple;
      }
    }
  }

  .logos {
    display: flex;
    padding: 0.5rem 0 1rem;
    justify-content: center;
    align-items: center;

    img {
      height: 5rem;
      width: auto;
    }

    .secondary-logos {
      display: inline-block;
      text-align: right;
    }
  }
}

@media all and (min-width: 768px) {
  .page-header {
    .logos {
      padding: 1.25rem 0;
      justify-content: space-between;

      img {
        height: 5rem;
        width: auto;
      }

      a {
        flex: 0 0 auto;
        margin-right: 1rem;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}
